<template>
	<b-modal
		ref="modal-combinations"
		@hidden="$emit('hidden')"
		title="Cintillos - Combinaciones"
		no-close-on-backdrop
		centered
		ok-only
		ok-title="Cancel"
		ok-variant="secondary"
		size="xl"
	>
		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="
								d-flex
								align-items-center
								justify-content-center justify-content-sm-start
							"
						>
							<span class="text-muted"
								>Mostrando {{ start_page }} a {{ to_page }} de
								{{ total_data }} registros</span
							>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="
								d-flex
								align-items-center
								justify-content-center justify-content-sm-end
							"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->

					<b-col
						cols="12"
						md="6"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon
							class="cursor-pointer"
							icon="RefreshCcwIcon"
							size="20"
							@click="resetSearch"
						/>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Buscar..."
								debounce="500"
							/>
							<b-button
								variant="primary"
								@click="generateCombinations()"
								v-if="isAdmin"
							>
								<span class="text-nowrap">Generar</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="'/api/cintillo-pata/get'"
					ref="refCombinationsTable"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="arrayColumns"
					primary-key="index"
					table-class="text-nowrap"
					responsive
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
					:filter="search_input"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(color)="{ item }">
						<div class="d-flex justify-content-center align-items-center mb-1">
							<div class="px-1 d-flex flex-column">
								<div>
									<feather-icon
										:style="`color: ${item.cintillo_color}; background: ${item.cintillo_color};`"
										class="rounded mb-1"
										:class="
											item.cintillo_description == 'BLANCO'
												? 'border fa-squaress'
												: ''
										"
										icon="SquareIcon"
										size="25"
									/>
								</div>
								<small>{{ item.cintillo_description }}</small>
							</div>

							<div
								class="px-1 d-flex flex-column"
								v-if="item.second_color_color"
							>
								<div>
									<feather-icon
										v-if="item.second_color_color"
										:style="`color: ${item.second_color_color}; background: ${item.second_color_color};`"
										class="rounded mb-1"
										:class="
											item.second_color_description == 'BLANCO'
												? 'border fa-squaress'
												: ''
										"
										icon="SquareIcon"
										size="25"
									/>
								</div>
								<small>{{ item.second_color_description }}</small>
							</div>
						</div>
						<!-- <div class="d-flex justify-content-start align-items-center"> -->
						<!-- <small class="ml-1">{{ item }}</small> -->
						<!-- </div> -->
					</template>
					<!-- <template #cell(second_color_description)="data">

          </template> -->
					<template #cell(created_by)="{ item }">
						{{ item.created_by_name }}
						{{ item.created_by_last_name }} <br />
						<small>{{ item.created_at | myGlobalDayWhour }}</small>
					</template>
					<template #cell(cintillo_pata_status)="{ item }">
						{{ item.cintillo_pata_status }} <br />
						<a
							href="#"
							@click="
								(selectedPosturaId = item.postura_id),
									(modalDetailPostura = true)
							"
							>{{ item.code }}</a
						>
					</template>
					<!-- <template #cell(actions)="data">
            acciones
          </template> -->
				</b-table>
			</div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="
							d-flex
							align-items-center
							justify-content-center justify-content-sm-start
						"
					>
						<span class="text-muted"
							>Mostrando {{ start_page }} a {{ to_page }} de
							{{ total_data }} registros</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="
							d-flex
							align-items-center
							justify-content-center justify-content-sm-end
						"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			id="detailPosturaModal"
			:title="`${modalTitle}`"
			v-model="modalDetailPostura"
			hide-footer
			size="xl"
			scrollable
			centered
		>
			<PosturaDetail
				@set-title="setTitle"
				:postura="{ id: selectedPosturaId }"
			></PosturaDetail>
		</b-modal>
	</b-modal>
</template>
<script>
// import vSelect from "vue-select";
import axios from "@/axios";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import PosturaDetail from "../../encaste/posturas/PosturaDetail.vue";
import modalMixin from "@/mixins/modal.js";
export default {
	name: "CombinationsList",
	mixins: [modalMixin],
	created() {
		this.getCintillosAndChickenlegs();
	},
	mounted() {
		this.toggleModal("modal-combinations");
	},
	components: {
		AppCollapse,
		AppCollapseItem,
		PosturaDetail,
	},
	data() {
		return {
			sortBy: "created_at",
			sortDesc: false,
			arrayColumns: [
				{
					key: "color",
					label: "Color",
					class: "text-center",
				},
				// {
				// 	key: "second_color_description",
				// 	label: "Segundo color",
				// 	class: "text-center",
				// },
				{
					key: "chickenleg_description",
					label: "Pata",
					class: "text-center",
				},
				{ key: "created_by", label: "Creado por", class: "text-center " },
				// {
				// 	key: "created_at",
				// 	label: "Fecha",
				// 	class: "text-center",
				// 	sortable: true,
				// },
				{
					key: "cintillo_pata_status",
					label: "Estado",
					class: "text-center",
					sortable: true,
				},
				// { key: "actions", label: "Acciones", class: "text-center " },
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			cintillo: null,
			loading: false,
			modalRegistrarCintillo: false,
			arrayCintillos: [],
			arrayColours: [],
			arrayChickenlegs: [],
			arrayColoursSorted: [],
			modalDetailPostura: false,
			selectedPosturaId: null,
			modalTitle: "",
		};
	},
	methods: {
		getCintillosAndChickenlegs() {
			axios.get("/api/cintillo-pata/get-colors-chickenleg").then(({ data }) => {
				this.arrayColours = data.colors;
				this.arrayColoursSorted = data.colors_sorted;
				this.arrayChickenlegs = data.chickenlegs;
			});
		},
		onChangeFilter() {
			this.$refs.refCombinationsTable.refresh();
		},
		myProvider(ctx) {
			const promise = axios.post(`${ctx.apiUrl}?page=${ctx.currentPage}`, {
				orderby: ctx.sortDesc == 1 ? "desc" : "asc",
				order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
				campo: ctx.filter,
				perpage: ctx.perPage,
			});

			return promise.then((data) => {
				const items = data.data.data;
				this.start_page = data.data.from;
				this.current_page = data.data.current_page;
				this.perpage = data.data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.data.last_page;
				this.total_data = data.data.total;
				this.to_page = data.data.to;
				return items || [];
			});
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refCombinationsTable.refresh();
		},
		async generateCombinations() {
			try {
				await axios.post("/api/cintillo-pata/save");
				this.$swal({
					icon: "success",
					title: "Combinaciones generadas!",
					text: "Se han generado correctamente las combinaciones!",
					customClass: {
						confirmButton: "btn btn-primary",
					},
					buttonsStyling: false,
				}).then((res) => {
					if (res) {
						this.$refs.refCombinationsTable.refresh();
					}
				});
			} catch (error) {
				console.error(error);
				throw this.showErrorSwal(error);
			}
		},
		setTitle(code_postura) {
			// console.log({ code_postura });
			this.modalTitle = code_postura;
		},
	},
};
</script>

<style lang="scss" scoped>
// .per-page-selector {
// 	width: 90px;
// }
// td.div {
// 	width: 100% !important;
// }
// @media (max-width: 960px) {
// 	.column-table {
// 		display: flex;
// 		flex-direction: column;
// 	}
// }
</style>

<style lang="scss" scoped>
// @import "@core/scss/vue/libs/vue-select.scss";
// @import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
